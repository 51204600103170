import React, {
    ElementRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import {
    FORM_FIELDS,
    validationSchema,
} from 'views/privacy-policy/components/gdpr-sidebar/validators';
import { sendGDPR } from 'api/send-gdpr';
import { Button } from 'components/button-new';
import { Dialog } from 'components/dialog';
import { resolveDialogContent } from 'components/dialog/utilities';
import { DialogContent } from 'components/dialog-content';
import { Input } from 'components/input-new';
import { FORM_STATUSES } from 'constants/form-statuses';
import { gaTrackedEvents } from 'constants/ga-tracked-events';
import { CONSTANTS } from 'constants/styles/constants';
import { useReCaptcha } from 'hooks/use-re-captcha';
import { func, instanceOf, node, shape } from 'prop-types';
import { getPageUrl, trackCustomEventGoogleAnalytics } from 'utilities/index';

import { customDialogContent } from './constants';

const SSidebarWrapper = styled.div`
    flex: 0.3;
    text-align: left;

    ${CONSTANTS.MEDIA.max1024`
        display: none;
    `}
`;

const SSidebarForm = styled.form`
    background: #ffffff;
    box-shadow: 0px 26px 38px #00000029;
    padding: 46px 24px;
    position: fixed;
    top: 291px;
    display: ${(props) => (props.isHidden ? 'none' : 'flex')};
    flex-direction: column;
    align-items: flex-start;

    .privacy-policy-email {
        margin-bottom: 12px;
        width: 287px;
        height: 40px;
    }

    .privacy-policy-gdpr-request {
        margin-bottom: 36px;
        width: 287px;
        height: 40px;
    }
`;

const SSidebarHeading = styled.h4`
    font-size: 24px;
    letter-spacing: 0px;
    color: var(--black-text-color);
    margin-bottom: 20px;
`;

export const GDPRSidebar = ({ setIsLoading, blogPostContentRef }) => {
    const [hideSidebar, setHideSidebar] = useState(false);
    const [formStatus, setFormStatus] = useState(FORM_STATUSES.IDLE);
    const dialogRef = useRef();
    const { formatMessage } = useIntl();

    const {
        control,
        formState: { isDirty, errors },
        handleSubmit,
        reset,
    } = useForm({
        resolver: validationSchema,
        defaultValues: {
            [FORM_FIELDS.EMAIL]: '',
            [FORM_FIELDS.GDPR_REQUEST]: '',
        },
    });

    const onFormSubmit = useCallback((status) => {
        setFormStatus(status);

        if (status !== FORM_STATUSES.IDLE) {
            dialogRef?.current?.showModal();
        }
    }, []);

    const onFailedLoad = () =>
        onFormSubmit(FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR);

    const { requestRecaptchaValidation } = useReCaptcha({
        isDirty,
        onFailedLoad,
    });

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', () => {
                const currentScrollPos = window.pageYOffset;
                if (
                    blogPostContentRef.current &&
                    blogPostContentRef.current.clientHeight
                ) {
                    if (
                        currentScrollPos + 300 >
                        blogPostContentRef.current.clientHeight
                    ) {
                        setHideSidebar(true);
                    } else {
                        setHideSidebar(false);
                    }
                }
            });
        }
    }, []);

    const onSubmit = handleSubmit(async ({ email, gdprRequest }) => {
        try {
            setIsLoading(true);

            const token = await requestRecaptchaValidation();
            await sendGDPR({
                email,
                gdprRequest,
                recaptchaResponse: token,
            });

            trackCustomEventGoogleAnalytics({
                ...gaTrackedEvents.SALES.SALES_QUICK_FORM,
                label: getPageUrl(),
            });
            onFormSubmit(FORM_STATUSES.SUCCESS);
            reset();
        } catch {
            onFormSubmit(FORM_STATUSES.ERROR);
        } finally {
            reset();
            setIsLoading(false);
        }
    });

    const onClose = () => {
        if (formStatus === FORM_STATUSES.RECAPTCHA_SCRIPT_NOT_LOADED_ERROR) {
            window.location.reload();
            return;
        }

        dialogRef?.current?.close();
        setFormStatus(FORM_STATUSES.IDLE);
    };

    return (
        <SSidebarWrapper>
            <SSidebarForm isHidden={hideSidebar} id="privacy-policy-form">
                <SSidebarHeading>
                    <FormattedMessage id="view-privacy-policy.gdpr-sidebar.heading" />
                </SSidebarHeading>
                <Input
                    name={FORM_FIELDS.EMAIL}
                    control={control}
                    label={formatMessage({
                        id: 'view-privacy-policy.gdpr-sidebar.email-input-label',
                    })}
                    error={errors[FORM_FIELDS.EMAIL]}
                    isRequired
                />
                <br />
                <Input
                    name={FORM_FIELDS.GDPR_REQUEST}
                    control={control}
                    label={formatMessage({
                        id: 'view-privacy-policy.gdpr-sidebar.gdpr-request-label',
                    })}
                    error={errors[FORM_FIELDS.GDPR_REQUEST]}
                    isRequired
                />
                <br />
                <Button onClick={onSubmit}>
                    <FormattedMessage id="general.send" />
                </Button>
            </SSidebarForm>
            <Dialog ref={dialogRef} testId="form_popup">
                <DialogContent
                    {...resolveDialogContent({
                        formStatus,
                        customDialogContent,
                    })}
                    firstParagraphMargin
                    onClose={onClose}
                />
            </Dialog>
        </SSidebarWrapper>
    );
};

GDPRSidebar.propTypes = {
    formatMessage: func,
    setIsLoading: func,
    blogPostContentRef: shape({ current: instanceOf(ElementRef) }),
};

GDPRSidebar.defaultProps = {
    formatMessage: () => {},
    setIsLoading: () => {},
    blogPostContentRef: { current: node },
};
